import React from "react";
import './Section2.css' 
import './Section2-Movil.css';

//*Components
import { Button } from "../../Button/Button";

export const Section2 = (prop) =>{

    const contentS2 = prop.contentS2;

    const title = contentS2.title
    const text = contentS2.desc
    const phrase = contentS2.phrase
    const btnText = contentS2.btn

    return(
        <section id="Section2" className="Section">
            <div className="S2-content">
                <div className="S2-content-info">
                    <h2 className="general-title-h2">{title}</h2> 
                    <p>{text}</p>
                    <p className="phrase">{phrase}</p>
                    <div className="S2-Btn">
                        <Button textBtn={btnText} bckColorClase='greenBtn' onIcon={false}/>
                    </div>
                </div>                
            </div>
            <div className="S2-image"> 
            </div>
        </section>
    )
}