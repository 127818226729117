import React from "react";
import './Card.css'

export const Card = ({id, tittle, text, text2, color,img}) => {

    const styles= {'background-color': color}

    return (
        <div className="o-card">
            <div className="o-card-cont">
                <div className="o-card-cont-left">
                    <div className="o-card-cont-left-indicator">
                        <p>{id}</p>
                    </div>
                    <img src={`/resources/Fondos/Services/${img}`} alt="" />
                </div>
                <div className="o-card-text">
                    <h3>{text}<br/><br/>{text2}</h3>
                </div>
            </div>
            <div className="o-card-tittle" style={styles}>
                <h3>{tittle}</h3>
            </div>

        </div>

    )
}