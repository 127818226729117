import React from "react";
import './Section5.css';



export const Section5 = () => {
    return (
        <section className=" o-be-part Section">
            <div className="o-img">
                <a className="o-img-link" href='www.wuadua.com.co' target="_blank">
                    <img src="/resources/Pancho/Pancho.png" alt="" />
                </a>
            </div>
        </section>
    )
}