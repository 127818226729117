import React from "react";
import './Section1.css'
import './Section1-Movil.css'
import { Slider } from "../../Carousel2/Carousel2";

const news =[
    {
        id: 1,
        img: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQULXjPMws_D04VuQ762nXMXbfkBJSVTjm5VA&usqp=CAU',
        title: '¿Tomas Decisiones con Datos o Vives el Riesgo de Tomar Decisiones Solo con tu Intuición?',
        link: 'https://revistaempresarial.com/tecnologia/inteligencia-de-negocios/tomas-decisiones-con-datos-o-vives-el-riesgo-de-tomar-decisiones-solo-con-tu-intuicion/'
    },
    {
        id: 2,
        img: 'https://media.licdn.com/dms/image/D4E12AQEl12dOvY61Eg/article-cover_image-shrink_423_752/0/1668629456046?e=1680739200&v=beta&t=Qe1OvlXF3FUHJF6sHvG-if1p5wNtwp59DDr8ZOOIKsA',
        title: 'Sin junior no hay senior',
        link: 'https://www.linkedin.com/pulse/sin-junior-hay-senior-lorena-sanchez/'
    },
    {
        id: 3,
        img: 'https://media.licdn.com/dms/image/D4E12AQFRdz9QKjsTyw/article-cover_image-shrink_423_752/0/1669301564894?e=1680739200&v=beta&t=vDH8ft4VkgG080iZVHzqENcbziWGAS70ejGtx9xQIP4',
        title: '¿Quieres un proyecto exitoso de analítica? Conoce los 4 componentes que determinan el éxito de un proyecto de analítica.',
        link: 'https://www.linkedin.com/pulse/quieres-un-proyecto-exitoso-de-anal%C3%ADtica-conoce-los-4-lorena-sanchez/'
    }
]

export const Section1 = (prop) =>{  

    const contentS1 = prop.contentS1

    const title = contentS1.title

    return(
        <section id="Section1" className="Section">
            <div className="S1-Logo"> 
                <img src="/resources/Vectores/Pancho.svg" alt=""/>
            </div>
            <div className="S1-image"> 
                <h2 className="S1-carousel-title general-title-h2">{title}</h2>
                <Slider  members={news} visibleItemsNumber={2}>
                    {(content) => (
                        <div className={`carousel-image-container news-style`}>
                            <img src={content.img} alt={content.img} />
                            <div className={`title-news`}>
                            <h3 className={`news-title`}>{content.title}</h3>
                            </div>     
                            <a href={content.link}>{''}</a>     
                        </div>
                    )}
                </Slider>  
            </div>
        </section>
    )
}