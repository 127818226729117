import React from "react";
import './Section4ABUC.css'
import './Section4ABUC-Movil.css'
import { NavLink } from "react-router-dom";
import { Button } from "../../Button/Button";


export const Section4ABUC = (prop) => {

    const contentS4 = prop.contentS4

    const title =  contentS4

    return (
        <section className="Section4ABUC-desc Section">
            <div className="btn-servc">            
                <NavLink className={`services-endp`} to="/services">
                    <Button textBtn={title} bckColorClase='greenBtn' onIcon={false}/>
                    <img className="btn-s-pancho" src="/resources/Pancho/panchoArms.svg" alt="pancho arms"/>
                </NavLink>
            </div>
        </section>
    )
}