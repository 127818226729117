import React from "react";

import { Section1 } from "../Components/KnowUsPage/Section1/Section1";
import { Section2 } from "../Components/KnowUsPage/Section2/Section2";
import { Section3 } from "../Components/KnowUsPage/Section3/Section3";
import { Section4 } from "../Components/KnowUsPage/Section4/Section4";
import { Section5 } from "../Components/KnowUsPage/Section5/Section5";

export const KnowUs = ({content}) =>{
    return (
        <>
            <Section1 contents1={content.cultura}/>
            <Section2 contents2={content.values}/>
            <Section3 contents3={content.team_wadua}/>
            <Section4 contents4={content.installation}/>
            <Section5/>
        </>
    )
}