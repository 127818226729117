import React from "react";
import { AboutusCDesc } from "../Components/AbUCompany/AboutusCDesc/AboutusCDesc";
import { Section2ABUC } from "../Components/AbUCompany/Section2ABUC/Section2ABUC";
import { Section3ABUC } from "../Components/AbUCompany/Section3ABUC/Section3ABUC";
import { Section4ABUC } from "../Components/AbUCompany/Section4ABUC/Section4ABUC";

export const AboutusCompany = ({content}) =>{

    return (
        <>            
            <AboutusCDesc contentS1={content.our_company} isEng={false}/>
            <Section2ABUC contentS2={content.do} isEng={false}/>
            <Section3ABUC contentS3={content.human_analytic} isEng={false}/>
            <Section4ABUC contentS4={content.last_button} isEng={false}/>
        </>
    )
}